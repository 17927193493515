.el-image {
    line-height: 0;
    .image-container {
        position: relative;
        img {
            width: 100%;
            height: auto;
        }
        picture {
            width: 100%;
            height: auto;
        }

    }
}
