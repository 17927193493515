.el-social-media-list {
    .page-wrapper {
        max-width: @content-max-width;
        margin-bottom: var(--space-mega);
    }

    ul {
        display: flex;
        column-gap: 22.5px;

        li {
            a {
                text-decoration: none;
                font-size: 3.5rem
            }
        }
    }
}