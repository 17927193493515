.cp-three-column-image-txt {
    .page-wrapper {
        margin-bottom: var(--space-mega);
    }

    .content {
        img {
            max-width: 100%;
        }
    }

    .columns {
        margin-top: var(--space-medium);
        display: flex;
        column-gap: var(--space-medium);
        justify-content: space-between;
        flex-wrap: wrap;

        .column {
            width: 100%;
            margin-bottom: var(--space-large);

            &:last-child {
                margin-bottom: 0px;
            }

            @media @min-mobile-medium {
                margin-bottom: 0px;
                max-width: ~"calc(50% - var(--space-medium))";
            }

            @media @min-tablet-hoch {
                max-width: ~"calc(33.33% - var(--space-medium))";
            }

            figure {
                width: 100%;
                figcaption {
                    padding-top: var(--space-small);
                    font-family: var(--font-family-alt);
                    font-size: 1.8rem;
                    font-weight: bold;
                }
            }
        }
    }
}