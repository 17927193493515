.cp-specialist-box {
    .page-wrapper {
        border: 1px solid var(--color-bg-box-grey-8);
        padding: var(--space-medium);
        margin-bottom: var(--space-mega);

        @media @min-mobile-medium {
            padding: var(--space-x-large);
        }
    }

    .el_title {
        margin-bottom: var(--space-large);
    }

    .boxes {
        display: flex;
        flex-wrap: wrap;

        .el-teaser-box-doc {
            width: 100%;
            margin-bottom: var(--space-medium);

            &:last-child {
                margin-bottom: 0px;
            }

            .content {
                position: relative;
                .button {
                    left: 0;
                    flex-wrap: nowrap;
                }
            }

            img {
                width: 100%!important;
            }
        }
    }
}