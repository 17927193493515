.cp-graphic-teasers {
    h2 {
        margin-bottom: 3rem;

        @media @min-tablet-hoch {
            margin-bottom: 6rem;
        }
    }


    .teasers-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;

        .graphic-teaser-item {
            position: relative;
            width: 100%;
            margin-bottom: var(--space--xx-large);

            .overview-link {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
            }

            @media @min-tablet-hoch {
                max-width: ~"calc(50% - 30px)";
            }

            &:last-child {
                margin-bottom: 0px;
            }

            @media @min-tablet-hoch {
                &:nth-last-child(1), &:nth-last-child(2) {
                    margin-bottom: 0px;
                }
            }

            &.transparent-svg {
                .graphic {
                    img {
                        background-color: var(--color-bg-brand);
                    }
                }
            }

            .graphic {
                line-height: 0;
                width: 100%;
                overflow: hidden;
                 img {
                     -webkit-transition: -webkit-transform .4s cubic-bezier(0, 0, 0.2, 1) 0s;
                     transition: -webkit-transform .4s cubic-bezier(0, 0, 0.2, 1) 0s;
                     transition: transform .4s cubic-bezier(0, 0, 0.2, 1) 0s;
                     transition: transform .4s cubic-bezier(0, 0, 0.2, 1) 0s, -webkit-transform .4s cubic-bezier(0, 0, 0.2, 1) 0s;

                     aspect-ratio: 475 / 343;
                     width: 100%;
                     height: auto;
                     max-height: 343px;
                 }
            }

            .text-box {
                //margin-top: -55px;
                position: relative;

                @media @min-tablet-hoch {
                    margin-top: -55px;
                }

                .inner {
                    padding: 25px 25px 55px;
                    background-color: var(--white);
                    border-bottom: 12px solid var(--color-brand);
                    z-index: 10;

                    @media @min-tablet-hoch {
                        margin: 0 35px;
                    }

                    header {
                        h3 {
                            color: var(--color-brand)
                        }
                    }

                    .content {
                        p {
                            line-height: 3rem;
                            font-weight: var(--font-weight-light);
                        }
                    }

                    .link-list {
                        display: flex;
                        flex-direction: row;
                        margin-top: 55px;

                        li {
                            margin-right: 3rem;

                            a {
                                color: var(--color-brand);
                                font-family: var(--font-family-medium);
                            }

                            &:last-child {
                                margin-right: 0;

                            }
                        }
                    }
                }
            }

            &.no-added-content {
                .text-box {
                    .inner {
                        padding: 55px 25px;
                    }
                }

                &:hover {
                    .graphic {
                        img {
                            transform: scale(1.1);
                        }
                    }
                    .text-box {
                        .inner {
                            background-color: #f8f8f8;
                        }
                    }
                }
            }
        }
    }
}