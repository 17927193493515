.cp-facts-box {
    .page-wrapper {
        border: 1px solid var(--color-bg-box-grey-8);
        padding: var(--space-medium);
        margin-bottom: var(--space-mega);

        @media @min-mobile-medium {
            padding: var(--space-x-large);
        }
    }

    .el_title {
        margin-bottom: var(--space-large);
    }


    .el-table {
        border-top: none;

        .table-row {
            &:last-child {
                border-bottom: none;
            }
        }
    }
}