.el-accordion-item {
    background-color: var(--white);

    .accordion-title {
        position: relative;
        display: block;
        border-top: 1px solid var(--grey);
        padding: var(--space-small) var(--space-x-small) var(--space-small);
        cursor: pointer;

        .accordion-title-inner {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            h3 {
                font-family: var(--font-family-base);
                font-weight: var(--font-weight-bold);
                font-size: var(--font-size-h3);
                margin-bottom: 0;
                color: var(--black);
            }

            span.toggle {
                &::before {
                    position: absolute;
                    right: var(--space-x-small);
                    top: 1.7rem;
                    font-family: "icomoon";
                    content: @icon-down;
                    color: var(--black);
                    font-size: 1.5rem;
                    pointer-events: none;
                    transition-duration: 0.3s;
                }
            }
        }
    }

    .accordion-container {
        height: 0;
        overflow: hidden;
        padding: 0;
        background-color: var(--white);
        transition: height var(--anim-duration) ease-in-out;

        .text-container {
            padding: var(--space-x-small) var(--space-small);
            padding-bottom: var(--space-medium);
            padding-left: var(--space-x-small);

            h3 {
                font-size: var(--font-size-h4);
                font-weight: var(--font-weight-bold);
            }

            .text {
                p {
                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }
    }

    &:last-child {
        .accordion-container {
            border-bottom: 1px solid var(--grey);
        }
    }

    &.open,
    &.already-open {
        > .accordion-title {
            .accordion-title-inner {
                span.toggle {
                    &::before {
                        content: @icon-up;
                    }
                }
            }
        }
    }

    &.already-open {
        > .accordion-container {
            height: auto;
        }
    }
}

section.grey {
    .el-accordion-item {
        background-color: var(--color-bg-box-grey-100);
        .accordion-container {
            background-color: var(--color-bg-box-grey-100);
        }
    }
}