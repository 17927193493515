.el-table {
    border-top: 1px solid var(--color-table-border);

    .table-row {
        padding:  20px 0;
        border-bottom: 1px solid var(--color-table-border);
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        @media @min-mobile-large {
            flex-direction: row;
        }


        .row-1 {
            width: 100%;
            font-weight: var(--font-weight-bold);
            font-family: var(--font-family-medium);
            padding-right: 0;

            @media @min-mobile-large {
                width: 50%;
                padding-right: 15px;
            }

            @media @min-tablet-hoch {
                width: 350px;
            }
        }

        .row-2 {
            width: 100%;

            @media @min-mobile-large {
                width: 50%;
                padding-left: 15px;
            }

            @media @min-tablet-hoch {
                width: ~"calc(100% - 350px)";
            }
        }
    }
}