button {
    background: transparent;
    color: inherit;
    border: transparent;
    padding: 0;
    font: inherit;
    cursor: pointer;
}


a.button {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 6rem;
    background-color: var(--color-link);
    border: 1px solid var(--color-link);
    color: var(--white);
    padding: var(--space-x-small) 3.0rem;
    text-align: center;
    text-decoration: none;

    font-weight: var(--font-weight-bold);
    font-family: var(--font-family-medium);
    transition: var(--anim-duration);

    &:hover {
        background-color: var(--color-link-hover);
        border: 1px solid var(--color-link-hover);
    }

    &.no-hover {
        &:hover {
            background-color: var(--color-brand);
            border: 1px solid var(--color-brand);
            color: var(--white);
        }
    }

    .icon:before {
        margin-right: 10px;
    }

    &.second {
        background-color: var(--color-bg-box-brand-100);
        border-color: var(--color-bg-box-brand-100);
    }
}