.el-search-bar {
    .page-wrapper {
        max-width: 815px;
        margin-bottom: var(--space-x-large);
    }

    .search-form-container {
        width: 100%;
        position: relative;
        height: 60px;

        span.icon {
            position: absolute;
            left: 20px;
            color: var(--black);
            background: var(--color-bg-box-grey-100);
            font-size: 2.3rem;
            font-weight: var(--font-weight-bold);
            text-decoration: none;

            &:hover {
                cursor: pointer;
            }
        }

        form.search-form {
            width: 100%;
            position: relative;
            overflow: hidden;
            float: right;
            transition: width var(--anim-duration) ease-in-out;

            input {
                background-color: var(--color-bg-box-grey-100);
                width: 100%;
                height: 60px;
                padding: 0 10px;
                padding-left: 64px;
                border: 0;
                font-size: 18px;
                font-weight: var(--font-weight-regular);
                font-family: var(--font-family-base);
                color: var(--black-30);

                &:focus {
                    outline: none !important;
                    border-color: transparent;
                    box-shadow: none;
                }
            }
        }
    }
}