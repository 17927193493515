.el-slider {
    position: relative;

    > .page-wrapper {
        max-width: 1120px;
        margin-top: var(--space-mega);
        margin-bottom: var(--space-mega);
        position: relative;
    }

    .el_title {
        margin-bottom: var(--space-medium);

        @media @min-tablet-hoch {
            margin-bottom: 6rem;
            margin-left: 60px;
            margin-right: 60px;
        }
    }

    .glide__track {
        .glide__slide {
            background-color: var(--white);
        }
        @media @min-tablet-hoch {
            background-color: transparent;
            margin-left: 60px;
            margin-right: 60px;
        }
    }

    .glide__slide {
        position: relative;
        height: auto;

        .el-slider-item {
            height: 100%;
        }
    }

    .glide-controls {
        .icon-prev {
            //transform: rotate(-90deg);
            font-size: 2.5rem;
            left: -20px;
            max-width: 20px;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto 0;

            @media @min-tablet-hoch {
                font-size: 4rem;
                left: 0px;
            }
        }

        .icon-next {
            //transform: rotate(-90deg);
            font-size: 2.5rem;
            max-width: 20px;
            position: absolute;
            right: -20px;
            top: 0;
            bottom: 0;
            margin: auto 0;

            @media @min-tablet-hoch {
                font-size: 4rem;
                right: 0px;
            }
        }
    }
}