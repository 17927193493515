// Fonts
@font_path: "../fonts/";

@font-face {
    font-family: "Brother1816Regular";
    src: url('@{font_path}Brother1816Regular/font.woff2') format('woff2'), url('@{font_path}Brother1816Regular/font.woff') format('woff');
}
@font-face {
    font-family: "Brother1816Medium";
    src: url('@{font_path}Brother1816Medium/font.woff2') format('woff2'), url('@{font_path}Brother1816Medium/font.woff') format('woff');
}
@font-face {
    font-family: "Brother1816BookItalic";
    src: url('@{font_path}Brother1816BookItalic/font.woff2') format('woff2'), url('@{font_path}Brother1816BookItalic/font.woff') format('woff');
}
@font-face {
    font-family: "Brother1816Book";
    src: url('@{font_path}Brother1816Book/font.woff2') format('woff2'), url('@{font_path}Brother1816Book/font.woff') format('woff');
}


@font-face {
    font-family: 'OpenSans';
    font-weight: 300;
    font-style: normal;
    src: url("@{font_path}OpenSans-Light.ttf") format("truetype")
}

@font-face {
    font-family: 'OpenSans';
    font-weight: 400;
    font-style: normal;
    src: url("@{font_path}OpenSans-Regular.ttf") format("truetype")
}

@font-face {
    font-family: 'OpenSans';
    font-weight: 500;
    font-style: normal;
    src: url("@{font_path}OpenSans-Medium.ttf") format("truetype")
}

html {
    /* 62.5% of 16px base font size is 10px */
    font-size: 62.5%;
}

body {
    font-size: var(--font-size-base);
    font-family: var(--font-family-base);
    font-weight: var(--font-weight-light);
    line-height: var(--line-height-base);
}

p {
    margin: 0 0 20px;

    &:last-of-type {
        margin: 0;
    }
}

h1, h2, h3, h4 {
    font-family: var(--font-family-book);
    font-weight: var(--font-weight-bold);
    margin: 0;
    padding: 0;
}

h1 {
    font-size: var(--font-size-h1);
    line-height: 1.25;
    margin-bottom: var(--space-small);
}

h2,
.h2 {
    font-size: var(--font-size-h2);
    font-weight: normal;
    line-height: 4.2rem;
    @media @min-mobile-medium {
        line-height: 5rem;
    }
    margin-bottom: var(--space-medium);
}

h3 {
    font-size: var(--font-size-h3);
    line-height: 35px;
    margin-bottom: var(--space-small);
}

h4 {
    font-size: var(--font-size-h4);
    margin-bottom: var(--space-small);
}

a,
.link-text {
    font-weight: var(--font-weight-bold);
    font-family: var(--font-family-medium);
    display: inline-block;
    color: var(--black);
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 5px;
    text-decoration-color: var(--color-brand);
    transition: var(--anim-duration);
    cursor: pointer;

    &:hover {
        color: var(--color-brand);
    }

    &.no-decoration {
        text-decoration: none;
    }

    &.link-box,
    &.video-link-box {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &:hover {
            cursor: pointer;
        }
    }

    &.underline-animation {
        position: relative;
        text-decoration: none;
        color: var(--color-brand);

        &:hover::before {
            left: auto;
            right: 0;
            width: 0;
            background-color: var(--color-brand);
        }
    }

    &.underline-animation::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background-color: var(--color-brand);
        transition: width .6s ease-in-out;
    }

}

.accordion-container {
    a {
        text-decoration-color: var(--black);
        text-decoration-thickness: 1px;
    }
}

strong {
    font-family: var(--font-family-medium);
    font-weight: normal;
}

