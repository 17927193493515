.cp-specialties-list {
    --list-red: #e02020;
    width: 100%;

    .page-wrapper {
        max-width: @content-max-width;
        margin-bottom: var(--space-mega);
    }

    .inner {

        > h3 {
            margin-bottom: 35px;
        }

        .items {
            .item {
                padding-top: var(--space-medium);
                padding-bottom: var(--space-medium);
                border-top: 1px solid var(--color-table-border);
                display: flex;
                font-size: 20px;
                justify-content: space-between;
                cursor: pointer;
                position: relative;

                .overview-link {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }

                .icon-down {
                    transform: rotate(-90deg);
                    font-size: 1.5rem;
                }

                &:last-child {
                    border-bottom: 1px solid var(--color-table-border);
                }
            }
        }


        .complete-list {
            margin-top: 15px;
            text-decoration: none;
            color: var(--list-red);
        }
    }
}