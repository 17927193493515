.cp-apply-now-bar {
    .page-wrapper {
        max-width: @content-max-width;

        @media @min-tablet-hoch {
            margin-bottom: var(--space-mega);
        }
    }

    .lead {
        margin-bottom: var(--space-medium);
    }

    &.fixed {
        position: fixed;
        bottom: 65px;
        width: 100%;
        left: 0;
        right: 0;
        background-color: var(--white);

        .page-wrapper {
            padding: var(--space-medium);

            @media @min-tablet-hoch {
                padding: 0px;
                margin-bottom: var(--space-medium);
                margin-top: var(--space-medium);
            }
        }

        @media @min-tablet-hoch {
            bottom: 0px;
        }
    }
}