
.cp-footer {
    padding-top: var(--space-mega);
    width: 100%;
    border-bottom: 32px solid var(--color-brand-light);
    font-size: 1.8rem;
    border-top: 16px solid var(--color-bg-box-grey-100);

    a {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-decoration-color: var(--black);
        color: var(--black);


        &:hover {
            color: var(--color-link);
        }
    }

    .footer-wrapper {
        width: 100%;
        max-width:1140px;
        margin: 0 auto;
        padding: 0 20px;

    }

    .footer-infos {
        .info-bar {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            row-gap: var(--space-medium);

            .info-bar-big {
                width: ~"calc(100% - 150px)";
                display: flex;
                row-gap: var(--space-medium);
                flex-wrap: wrap;
                flex-direction: row;

                > div {
                    width: 100%;

                    @media @min-tablet-hoch {
                        width: 33.33%;
                    }
                }

            }
            .info-bar-small {
                width: 100%;

                @media @min-tablet-hoch {
                    width: 150px;
                    padding-left: 30px;
                }
            }
        }
    }

    .footer-somedia {
        ul.social-media {
            display: flex;
            flex-direction: row;
            margin-top: 4rem;
            margin-bottom: 8rem;

            li {
                margin-right: 20px;
                display: flex;
                align-items: center;

                a {
                    text-decoration: none;

                    &:before {
                        font-size: 3.2rem;
                        color: var(--black);
                        transition-duration: var(--anim-duration);
                    }

                    &:hover {
                        &:before {
                            color: var(--color-link);
                        }
                    }
                }
            }
        }
    }

    .footer-logos {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        row-gap: var(--space-large);

        @media @min-tablet-hoch {
            row-gap: var(--space-medium);
        }

        .logo-element-1 {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            @media @min-tablet-hoch {
                width: 50%;
                padding-right: 30px;
            }

            .logos-bar {
                .logo-1 {
                   padding-right: 15px;
                    margin-bottom: var(--space-small);

                    @media @min-tablet-hoch {
                        margin-bottom: 0px;
                    }
                }
                .logo-1,
                .logo-2 {
                    img {
                        height: 55px;
                        width: auto;
                    }
                }
            }
        }

        .logo-element-2 {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            @media @min-tablet-hoch {
                width: ~"calc(50% - 150px)";
                padding-right: 30px;
            }

            .logo-3 {
                img {
                    height: 70px;
                    width: auto;
                }
            }
        }

        .logo-element-3 {
            width:  100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            @media @min-tablet-hoch {
                width: 150px;
                padding-right: 30px;
            }

            .logo-4 {
                img {
                    height: auto;
                    width: 120px;
                }
            }

        }

        .logo-element-1,
        .logo-element-2,
        .logo-element-3 {
            .footer-title {
                font-size: 1.6rem;
                font-weight: var(--font-weight-bold);
                font-family: var(--font-family-medium);
                margin-bottom: 20px;
                width: 100%;
            }
            .logos-bar {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                row-gap: var(--space-medium);

                > div {
                    width: 100%;

                    @media @min-tablet-hoch {
                        width: auto;
                    }
                }
            }
        }
    }

    .copyright {
        margin-top: 5.5rem;
        margin-bottom: 6rem;
        font-size: 1.4rem;
    }
}
