.el-txt-img-content {
    p + h2,
    p + h3,
    p + h4,
    ul + h2,
    ul + h3,
    ul + h4 {
        padding-top: var(--space-small);
    }

    img {
        width: 100%;
        padding-bottom: var(--space-medium);
    }

    &.left {
        img {
            margin-top: var(--space-x-small);
            @media @min-tablet-hoch {
                max-width: 50%;
                float: left;
                padding-right: var(--space-large);
            }
        }
    }

    &.right {
        img {
            margin-top: var(--space-x-small);
            @media @min-tablet-hoch {
                max-width: 50%;
                float: right;
                padding-left: var(--space-large);
            }
        }
    }
}