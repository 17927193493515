.el-page-title {
    width: 100%;

    .page-wrapper {
        max-width: @content-max-width;
        margin-bottom: var(--space-mega);
    }

    h1 {
        font-size: 40px;
        line-height: 50px;
    }

    .lead {
        font-family: var(--font-family-base);
        font-size: 20px;
        line-height: 35px;
    }
}