.el-appointment-box {
    @media @min-mobile-medium {
        padding: 0 20px;
    }

    .inner {
        width: 100%;
        max-width: 985px;
        background-color: var(--white);
        border: 1px solid var(--color-table-border);
        margin: 0 auto;
        padding: var(--space-small);

        @media @min-mobile-medium {
            padding: 35px 45px;
        }

        @media @min-tablet-quer {
            padding: 35px 45px 35px 80px;
        }

        .content-container {
            width: 100%;
            max-width: 550px;
            margin: 0 auto;
            display: flex;
            flex-direction: column-reverse;
            flex-wrap: wrap;

            @media @min-tablet-hoch {
                flex-direction: row;
                max-width: 100%;
            }

            .text-container {
                width: 100%;

                .text {
                    margin-bottom: 5rem;
                }

                @media @min-tablet-hoch {
                    width: ~"calc(100% - 300px)";
                    padding-right: 30px;
                }

                @media @min-tablet-quer {
                    width: ~"calc(100% - 340px)";
                    padding-right: 45px;
                }

                .button {
                    flex-wrap: nowrap;
                }
            }

            figure {
                width: 100%;
                margin-bottom: 3rem;

                @media @min-tablet-hoch {
                    width: 300px;
                    margin-bottom: 0;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                @media @min-tablet-quer {
                    width: 340px;
                }
            }
        }
    }
}