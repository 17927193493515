.el-breadcrumb {

    .page-wrapper {
        margin-bottom: 120px;
    }


    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        li {
            margin-right: 1.0rem;
            font-size: 1.4rem;

            a {
                font-family: var(--font-family-base);
                color: var(--black-30);
                text-decoration: none;

                &:hover {
                    color: var(--black);
                }
            }

            &::after {
                font-family: "icomoon";
                font-size: 0.8rem;
                margin-left: 0.7rem;
                content: @icon-breadcrumb;
                color: var(--black-30);
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
    }

    &.small {
        .page-wrapper {
            margin-top: 20px;
            margin-bottom: 75px;
        }
    }
}