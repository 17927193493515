.cp-doctor-intro {
    .page-wrapper {
        max-width: 730px;
    }

    .inner {
        .el-image {
            margin-bottom: var(--space-large);
            img {
                width: auto;
            }
        }

        .el_title {
            margin-bottom: var(--space-large);
        }

        .el-button-bar {
            margin-bottom: var(--space-large);
            .el_title {
                h2 {
                    font-size: var(--font-size-h3);
                }
                margin-bottom: var(--space-medium);
            }
        }

        .cp-accordion {
            margin-bottom: var(--space-large);
        }
    }
}