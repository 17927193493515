.el-image-gallery {
    overflow: hidden;

    .gallery-container {
        margin-top: var(--space-medium);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .gallery-item {
            margin-bottom: var(--space-medium);

            > a {
                position: relative;
                display: block;
                height: 250px;
                border: 1px solid var(--black);

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    margin: 0 auto;
                    transition-duration: var(--anim-duration);
                }

                span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    height: 3.0rem;
                    width: 3.0rem;
                    font-size: 19px;
                    color: var(--color-brand);
                    background-color: var(--white);
                    border-radius: 50%;
                    transition-duration: var(--anim-duration);
                    opacity: 0;
                    cursor: pointer;

                    &::before {
                        position: absolute;
                        top: 0.5rem;
                        left: 0.5rem;
                    }
                }

                &:hover {
                    span {
                        opacity: 1;
                    }

                    img {
                        opacity: 0.3;
                    }
                }
            }

            @media @min-mobile-medium {
                > a {
                    height: 300px;
                }
            }

            @media @min-mobile-large {
                > a {
                    height: 200px;
                }
            }

            @media @min-tablet-hoch {
                > a {
                    height: 175px;
                }

            }
        }
    }
}