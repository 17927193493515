.main.basic-page {
    overflow: hidden;
}

.page-wrapper {
    width: 100%;
    max-width: ~"calc(@{wrapper-width} + 50px)";
    padding-left: var(--space-medium);
    padding-right: var(--space-medium);
    margin: 0 auto;

    .page-wrapper {
        padding-left: 0px;
        padding-right: 0px;
    }
}

section.content-section + section.booklet {
    margin-top: var(--space-mega);
}

section {
    &.content-section {
        &.fullwidth {
            > .page-wrapper {
                max-width: 100%;
                padding-left: 0px;
                padding-right: 0px;
            }
        }

        &.light-grey {
            background-color: var(--color-bg-box-grey-8);

            > .page-wrapper {
                padding-top: var(--space-mega);
                padding-bottom: var(--space-mega);
            }

            & + & {
                > .page-wrapper {
                    padding-top: 0px;
                }
            }
        }

        &.grey {
            background-color: var(--color-bg-box-grey-100);

            > .page-wrapper {
                padding-top: var(--space-mega);
                padding-bottom: var(--space-mega);

                .page-wrapper {
                    margin-bottom: 0px;
                }
            }

            & + & {
                > .page-wrapper {
                    padding-top: 0px;
                }
            }

            .el-slider {
                > .page-wrapper {
                    margin-top: 0px;
                }
            }

            &.fullwidth {
                > .page-wrapper {
                    padding-left: var(--space-medium);
                    padding-right: var(--space-medium);
                }
            }
        }
    }
}

[class^="wrapper-content-"] {
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;

    &.small {
        max-width: @wrapper-content-small;
    }

    &.medium {
        max-width: @wrapper-content-medium;
    }

    &.large {
        max-width: @wrapper-content-large;
    }
}



.col-2,
.col-3,
.col-4 {
    display: flex;
    flex-direction: column;
}

.col-2 {
    @media @min-tablet-quer {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    > div {
        width: 100%;
        @media @min-tablet-quer {
            width: ~"calc(50% - 20px)";
        }
    }
}

.col-3 {
    @media @min-tablet-quer {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    > div {
        width: 100%;
        @media @min-tablet-quer {
            width: ~"calc(33.33% - 26.5px)";
        }
    }

}

.col-4 {
    @media @min-mobile-large  {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    > div {
        width: 100%;

        @media @min-mobile-large {
            width: ~"calc(50% - 20px)";
        }

        @media @min-tablet-hoch {
            width: ~"calc(33.33% - 26.5px)";
        }

        @media @min-tablet-quer {
            width: ~"calc(25% - 30px)";
        }
    }
}

