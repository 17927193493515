/* CSS Form-Builder update.ch */
/* font family and size for Inputfield forms */

body.InputfieldColumnWidthsInit {
    margin-top: -1px;
}

.Inputfield input:-webkit-autofill,
.Inputfield input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
.Inputfield input:focus,
.Inputfield textarea:focus,
.Inputfield select:focus {
    outline: none !important;
    border: 1px solid var(--grey) !important;
}

.FormBuilder,
.Inputfield,
.Inputfield input,
.Inputfield textarea,
.Inputfield select,
.Inputfield button,
.ui-widget {
    font-family: var(--font-family-base);
    font-size: 18px;
    line-height: 27px;
    box-sizing: border-box;
}

.Inputfield {
    margin: 0;
}

.InputfieldContent {
    font-family: var(--font-family-base);
    font-size: 18px;
    line-height: 27px;
    font-weight: 300;
}

.InputfieldHeader {
    font-family: var(--font-family-base);
    font-size: 18px;
    line-height: 27px;
    font-weight: 300;
}

.success {
    font-size: 21px;
    line-height: 30px;
    font-weight: 300;
}

/* additional padding for inputs and textareas */

.Inputfield input,
.Inputfield select {
    height: 48px;
}

.Inputfield input,
.Inputfield textarea,
.Inputfield select {
    width: 100%;
    padding: 0 10px;
    border: 1px solid transparent;
    background-color: #f5f5f5;
}

.Inputfield select {
    border: 1px solid #d3d3d3;
    background-color: transparent;
    appearance: none;
}

.InputfieldSelect {
    position: relative;
    display: flex;
    align-items: center;
    &:after {
        position: absolute;
        content: @icon-down;
        font-size: 1.2rem;
        right: var(--space-medium);
        font-family: @icomoon-font-family;
    }
}

.Inputfield textarea {
    padding-top: 5px;
    padding-bottom: 5px;
}

/* error messages */

.FormBuilder .error,
.InputfieldForm .error {
    color: var(--error);
    font-weight: bold;
}

/* wrapper for label + input */

.Inputfield {
    margin-bottom: 2em;
}

fieldset.Inputfield {
    margin-bottom: 0;
}


/* submit button */

.InputfieldForm button {
    padding: 0.5em 1em;
}


/* label header */

.Inputfield .InputfieldHeader {
    display: block;
    padding-bottom: 0.25em;
}

/* show a customized-colored asterisk after label when field "required" */

.InputfieldStateRequired>.InputfieldHeader:first-child:after {
    color: var(--grey) !important;
    content: '*';
}

/* descriptive text within Inputfields */

.Inputfield .notes,
.Inputfield .description {
    margin-bottom: 0.25em;
    margin-top: 0;
    opacity: 0.6;
}

/* styling for fieldsets and legend */

fieldset.InputfieldFieldset {
    padding: 0;
    border: none;
}

fieldset.InputfieldFieldset legend {
    font-family: var(--font-family-base);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-h2);
    line-height: 1.25;
    margin-bottom: var(--space-small);
}

/* honeypot */

#wrap_Inputfield- {
    display: none;
}

.Inputfield {
    button {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: fit-content;
        height: 6rem;
        background-color: var(--color-brand);
        border: 1px solid var(--color-brand);
        color: var(--white);
        padding: var(--space-x-small) 3.0rem;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-family: var(--font-family-medium);
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-base);
        transition: var(--anim-duration);


        &:hover {
            background-color: var(--color-link-hover);
            border: 1px solid var(--color-link-hover);
        }
    }
}

/* clearfix */

.pw-clearfix:before,
.pw-clearfix:after {
    content: "";
    display: table;
    border-collapse: collapse;
}

.pw-clearfix:after {
    clear: both;
}

/* Schöne Check- und Radio-Buttons
-----------------------------------------------------------------------*/
input[type="radio"],
input[type="checkbox"] {
    display: none;
}

input[type='checkbox']:checked,
input[type='checkbox']:not(:checked),
input[type='radio']:checked,
input[type='radio']:not(:checked) {
    background: transparent;
    position: relative;
    visibility: hidden;
    margin:0;
    padding:0;
}

input[type='checkbox'] + span,
input[type='radio'] + span {
    cursor: pointer;
}

input[type='checkbox']:checked + span::before,
input[type='checkbox']:not(:checked) + span::before,
input[type='radio']:checked + span::before,
input[type='radio']:not(:checked) + span::before {
    content:' ';
    display:inline-block;
    width: 21px;
    height: 21px;
    position: relative;
    top: 4px;
    border: 1px solid #D3D3D3;
    background: var(--white);
    margin-right: var(--space-small);
    box-shadow: inset 0 1px 1px 0 rgba(0,0,0,.1);
}

input[type='radio']:checked + span::before,
input[type='radio']:not(:checked) + span::before {
    border: 1px solid var(--black);
}

input[type='checkbox']:checked + span::before,
input[type='checkbox']:not(:checked) + span::before {
    top: 0px;
}

input[type=radio]:checked + span::before,
input[type=radio]:not(:checked) + span::before {
    border-radius: 30px;
}

@media (hover: hover) {
    input[type='radio']:hover + span::before {
        background: var(--color-brand);
        box-shadow: inset 0 0 0 2px var(--white);
    }
}

input[type='radio']:checked  + span::before {
    background: var(--color-brand);
    box-shadow: inset 0 0 0 2px var(--white);
}

.InputfieldCheckboxesStacked {

    label {
        display: flex;

        .pw-no-select {
            display: flex;
            align-items: center;
        }
    }
}

input[type='checkbox']:checked  + span::before {
    content: @icon-close;
    font-size: 1.5rem;
    text-align: center;
    font-family: @icomoon-font-family;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-brand);
}

@media (hover: hover) {
    input[type='checkbox']:hover + span::before {
        content: @icon-close;
        font-size: 1.5rem;
        text-align: center;
        font-family: @icomoon-font-family;
        top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color-brand);
    }
}

.pw-no-select {
    display: inline-block;
    padding-left: 33px;
    padding-right: 25px;
    width: 100%;
    //font-weight: bold;
    &::before {
        margin-left: -33px;
    }
}

input[type="checkbox"] + span::before {
    display: inline-block;
    width: 21px;
    height: 21px;
    cursor: pointer;
}

.InputfieldSubmit {
    margin-bottom: 0px;
}

.InputfieldCheckboxes, .InputfieldRadios {
    label {
        font-family: var(--font-family-base);
    }
}