.el-page-divider {
    .page-wrapper {
        max-width: @content-max-width;
        //padding-top: 20px;
        margin-bottom: var(--space-mega);

        .inner {
            hr {
                border: 0.5px solid var(--color-table-border);
            }
        }
    }
}