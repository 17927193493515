.el-article-teaser {
    background-color: var(--color-bg-box-grey-100);

    .page-wrapper {

    }

    .inner {
        .el-image {
            margin-bottom: var(--space-medium);
        }

        .date {
            font-size: 1.8rem;
            margin-bottom: var(--space-small);
        }

        h3 {
            margin-bottom: var(--space-medium);
        }

        .text {
            margin-bottom: var(--space-medium);
        }

        .article-url {
            position: relative;
            text-decoration: none;
            color: var(--color-brand);
            font-family: var(--font-family-medium);

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 2px;
                background-color: var(--color-brand);
                transition: width .6s ease-in-out;
            }

            &:hover {
                color: var(--color-brand);

                &::before {
                    left: auto;
                    right: 0;
                    width: 0;
                    background-color: var(--color-brand);
                }
            }
        }
    }
}