.cp-accordion {
    .page-wrapper {
        margin-bottom: var(--space-mega);
    }
    .el-accordion-item {
        //margin-bottom: var(--space-medium);

        &:last-child {
            margin-bottom: var(--space-small);
        }
    }
    .el_title {
        margin-bottom: var(--space-large);
        .lead {
            font-family: var(--font-family-medium);
        }
    }
}
