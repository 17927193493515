.el-media-box {
    --feedback-default-theme: rgba(196, 186, 191, 0.2);

    &.theme-1 {
        --feedback-default-theme: rgba(163, 180, 193, 0.2);
    }

    &.theme-2 {
        --feedback-default-theme: rgba(196, 206, 184, 0.2);
    }


    .page-wrapper {
        max-width: 986px;
        margin-bottom: var(--space-mega);
    }

    .inner {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        .media-container {
            display: none;
        }

        .text-container {
            background-color: var(--feedback-default-theme);
            width: 100%;
            position: relative;
            padding: 75px 85px;

            h2 {
                width: 100%;
                max-width: 815px;
                margin: 0 auto;
                font-size: 20px;
                line-height: 1.75;
                margin-top: var(--space-medium);
                font-family: var(--font-family-medium);
            }

            .content {
                width: 100%;
                font-family: var(--font-family-book-italic);
                max-width: 815px;
                margin: 0 auto;
                line-height: 35px;
            }
        }

        &.video-feedback {
            .media-container {
                width: 100%;
                display: block;

                .video {
                    background-color: var(--black);
                    padding: 56.25% 0 0 0;
                    position: relative;

                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .text-container {
                display: flex;
                flex-direction: column-reverse;
                h2 {
                    margin-top: 0px;
                    margin-bottom: var(--space-medium);
                }

                .content {
                    font-family: var(--font-family-base);
                }
            }

        }

    }


}

