.cp-job-intro {
    .page-wrapper {
        max-width: @content-max-width;
    }

    .inner {
        h1 {
            font-size: 4rem;
            margin-bottom: var(--space-large);
        }

        .info-table {
            tr {
                td {
                    padding-bottom: var(--space-medium);
                    &:first-child {
                        width: 175px;
                        font-weight: var(--font-weight-bold);
                        font-family: var(--font-family-medium);
                    }
                }
            }
        }

        .media {
            display: flex;
            flex-wrap: wrap;
            column-gap: 35px;
            row-gap: 35px;
            margin-bottom: var(--space-mega);

            .media-item {
                max-width: 100%;
                width: 100%;

                @media @min-mobile-medium {
                    max-width: ~"calc(50% - 17.5px)";
                }
            }
        }

        .cp-accordion {
            .el_title {
                h2 {
                    font-size: 2.5rem;
                }
            }
        }
    }
}