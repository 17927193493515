@import "variables";

@font-face {
  font-family: '@{icomoon-font-family}';
  src:  url('@{icomoon-font-path}/@{icomoon-font-family}.eot?d2ugms');
  src:  url('@{icomoon-font-path}/@{icomoon-font-family}.eot?d2ugms#iefix') format('embedded-opentype'),
    url('@{icomoon-font-path}/@{icomoon-font-family}.ttf?d2ugms') format('truetype'),
    url('@{icomoon-font-path}/@{icomoon-font-family}.woff?d2ugms') format('woff'),
    url('@{icomoon-font-path}/@{icomoon-font-family}.svg?d2ugms#@{icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '@{icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mail {
  &:before {
    content: @icon-mail; 
  }
}
.icon-facebook {
  &:before {
    content: @icon-facebook; 
  }
}
.icon-instagram {
  &:before {
    content: @icon-instagram; 
  }
}
.icon-linkedin {
  &:before {
    content: @icon-linkedin; 
  }
}
.icon-youtube {
  &:before {
    content: @icon-youtube; 
  }
}
.icon-favicon {
  &:before {
    content: @icon-favicon; 
  }
}
.icon-menu {
  &:before {
    content: @icon-menu; 
  }
}
.icon-close {
  &:before {
    content: @icon-close; 
  }
}
.icon-breadcrumb {
  &:before {
    content: @icon-breadcrumb; 
  }
}
.icon-down {
  &:before {
    content: @icon-down; 
  }
}
.icon-up {
  &:before {
    content: @icon-up; 
  }
}
.icon-next {
  &:before {
    content: @icon-next; 
  }
}
.icon-prev {
  &:before {
    content: @icon-prev; 
  }
}
.icon-play {
  &:before {
    content: @icon-play;     
    color: #000;
  }
}
.icon-publikationen {
  &:before {
    content: @icon-publikationen; 
  }
}
.icon-search {
  &:before {
    content: @icon-search; 
  }
}
.icon-phone {
  &:before {
    content: @icon-phone; 
  }
}
.icon-calendar {
  &:before {
    content: @icon-calendar; 
  }
}

