.cp-teaser-bar {
    overflow: hidden;

    .el-media-box {
        margin-bottom: var(--space-large);

        &:last-child {
            margin-bottom: var(--space-small);
        }

        @media @min-tablet-quer {
            margin-bottom: var(--space-small);
        }

        .inner {
            display: flex;
            flex-direction: column;

            .media-container {
                width: 100%;
            }

            .text-container {
                width: 100%;

                h2 {
                    font-size: var(--font-size-h3);
                }
            }

            @media @min-mobile-large {
                flex-direction: row;
                width: 100%;
                .media-container {
                    width: 50%;
                    margin-top: var(--space-x-small);
                    padding-right: 20px;
                }

                .text-container {
                    width: 50%;
                    padding-left: 20px;
                }
            }

            @media @min-tablet-hoch {
                .media-container {
                    width: 33.33%;
                    padding-right: 27px;
                }

                .text-container {
                    width: 66.66%;
                    padding-left: 13px;
                }
            }

            @media @min-tablet-quer {
                flex-direction: column;
                .media-container {
                    width: 100%;
                    margin-top: 0;
                    padding-right: 0;
                }

                .text-container {
                    width: 100%;
                    padding-left: 0;
                }
            }
        }
    }
}