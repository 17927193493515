.el-side-shortcuts {
    width: 100%;
    background-color: var(--white);
    position: fixed;
    z-index: 15;
    margin-top: 100px;
    right: 0;
    bottom: 0;

    @media @min-tablet-hoch {
        background-color: transparent;
        width: fit-content;
        bottom: initial;
    }

    .page-wrapper {
        padding: 0px;
    }

    .inner {
        ul {
            display: flex;
            justify-content: flex-end;

            @media @min-tablet-hoch {
                display: block;
            }

            li {
                display: flex;
                flex-direction: row-reverse;
                align-items: flex-start;
                margin-left: 20px;
                margin-bottom: var(--space-x-small);
                margin-top: var(--space-x-small);

                @media @min-tablet-hoch {
                    margin-bottom: 20px;
                }
                a {
                    text-decoration: none;
                    background-color: var(--color-brand);

                    span {
                        width: 55px;
                        height: 55px;
                        font-size: 30px;
                        display: block;
                        align-content: center;
                        color: var(--white);
                        text-align: center;
                    }

                    &:hover {
                        background-color: #a38787;
                    }
                }

                .text-box {
                    display: none;
                    font-size: 2rem;
                    background-color: #a38787;
                    padding: var(--space-medium);
                    padding-right: 55px;
                    color: var(--white);
                    position: absolute;
                    width: fit-content;
                    min-width: 500px;

                    a {
                        color: var(--white);
                        background-color: transparent;
                        text-decoration: underline;
                        text-decoration-color: var(--white);
                        text-decoration-thickness: 1px;
                    }
                }
                
                &.open {
                    > a {
                        z-index: 10;
                        background-color: #a38787;
                    }
                    .text-box {
                        display: block;
                    }
                }
            }
        }
    }
}