.el-booklet {
    background-color: var(--color-bg-box-brand-17);
    padding: 5rem 0 4.5rem;

    @media @min-tablet-hoch {
        padding: 6.5rem 0 5.5rem;
    }

    .booklet-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        @media @min-tablet-hoch {
            flex-direction: row;
        }

        figure.booklet {
            width: 100%;
            @media @min-tablet-hoch {
                width: 50%;
                padding-right: 30px;
            }

            img {
                width: 100%;
                max-width: 275px;
                margin: 0 auto;
            }
        }

        .content {
            width: 100%;
            text-align: center;
            align-content: center;

            @media @min-tablet-hoch {
                text-align: left;
                width: 50%;
                padding-left: 30px;
            }

            h2 {
                margin-bottom: 4.5rem;
            }

            a.button {
                margin: 0 auto;

                @media @min-tablet-hoch {
                    margin: 0;
                }
            }


        }

    }


}