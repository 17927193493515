/* BREAK POINTS Booklet dividers for different pages
_________________________________________________________________*/
@breakpoint-mobile-medium: 480px;
@breakpoint-mobile-large: 600px;
@breakpoint-tablet-hoch: 760px;
@breakpoint-tablet-quer: 1020px;
@breakpoint-desktop: 1200px;
@wrapper-width: 1260px;
@wrapper-content-large: 1260px;
@wrapper-content-medium: 1140px;
@wrapper-content-small: 1050px;

@content-max-width: 815px;

@min-mobile-medium: ~"screen and (min-width: @{breakpoint-mobile-medium})";
@min-mobile-large: ~"screen and (min-width: @{breakpoint-mobile-large})";
@min-tablet-hoch: ~"screen and (min-width: @{breakpoint-tablet-hoch})";
@min-tablet-quer: ~"screen and (min-width: @{breakpoint-tablet-quer})";
@min-desktop: ~"screen and (min-width: @{breakpoint-desktop})";
@min-wrapper-width: ~"screen and (min-width: @{wrapper-width})";

@max-tablet-hoch: ~"screen and (max-width: @{breakpoint-tablet-hoch})";

@header-height: 12.5rem;

:root {
    /* COLORS
    _____________________________________________________________*/

    --color-brand: #dba28b;             // Beige/Braun - Logo
    --color-brand-light: #d8ac9e;       // Background - Icon
    --color-brand-light-30: rgba(216, 118, 158, 0.30);
    --color-brand-light-85: rgba(216, 118, 158, 0.85);
    --color-brand-light-100: rgba(139, 118, 1, 1);

    --color-bg-box-brand-17: rgba(163, 135, 135, 0.17);
    --color-bg-box-brand-30: rgba(205, 154, 139, 0.30);
    --color-bg-box-brand-100: rgba(163, 135, 135, 1);
    --color-bg-box-grey-8: rgba(172, 169, 168, 0.8);
    --color-bg-box-grey-15: rgba(154, 145, 130, 0.15);
    --color-bg-box-grey-100: rgba(248, 248, 248, 1);
    --color-table-border: #cccccc;


    --white: #fff;
    --black: #000;
    --black-30: rgba(0, 0, 0, 0.3);
    --grey: #777777;

    --color-link: #dba28b;
    --color-link-hover: #a38787;

    --error: #cc0000;
    --success: #00aa00;

    /* THEMES COLORS
    _____________________________________________________________*/
    .default {
        --color-brand: #cf9b8c;         // Brand-Color
        --color-textlink: #d8ac9e;
    }

    .theme-1 {                          // ästhetische Chirurgie
        --color-brand: #a38787;
        --color-textlink: #a38787;
        --color-bg-brand: #f2e4e4;
    }

    .theme-2 {                          // rekonstruktive Chirurgie
        --color-brand: #b69676;
        --color-textlink: #b69676;
        --color-bg-brand: rgba(224, 190, 146, 0.2);
    }

    .theme-3 {                          // ästhetische Medizin
        --color-brand: #7ea481;
        --color-textlink: #7ea481;
        --color-bg-brand: rgba(126, 164, 129, 0.2);
    }

    .theme-4 {                          // Lymphchirurgie
        --color-brand: #8e98a0;
        --color-text: #8e98a0;
        --color-bg-brand: rgba(163, 180, 193, 0.2);
    }

    .theme-5 {
        --color-brand: #ad98aa;
        --color-text: #ad98aa;
        --color-bg-brand: rgba(173, 152, 170, 0.25);
    }



    /* FONTS
    _____________________________________________________________*/

    --font-family-base: 'Brother1816Book', Arial, Helvetica, Sans-serif;
    --font-family-medium: 'Brother1816Medium', Arial, Helvetica, Sans-serif;
    --font-family-book-italic: 'Brother1816BookItalic', Arial, Helvetica, Sans-serif;
    --font-family-alt: 'Brother1816Regular', Arial, Helvetica, Sans-serif;

    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-bold: 500;
    --font-weight-x-bold: 800;

    --font-size-base: 2rem;
    --line-height-base: 2.7rem;
    --font-size-big: 2rem;
    --line-height-big: 4rem;
    --font-size-lead: 2.0rem;
    --line-height-lead: 1.35;

    --font-size-h1: 3.0rem;
    --font-size-h2: 2.7rem;
    --font-size-h3: 2.5rem;
    --font-size-h4: 2.1rem;
    --font-size-h5: 1.8rem;

    @media @min-tablet-hoch {
        --font-size-lead: 2.1rem;

        --font-size-h1: 5rem;
        --font-size-h2: 4rem;
        --font-size-h3: 2.5rem;
        --font-size-h4: 2.4rem;
        --font-size-h5: 2.1rem;
    }


    /* Links -------------------------------------- */
    --bg-height: 2px;


    /* SPACES
    _____________________________________________________________*/

    --space-x-small: .5rem;
    --space-small: 1.5rem;
    --space-medium: 2.5rem;
    --space-large: 5.0rem;
    --space-x-large: 7.5rem;
    --space--xx-large: 10rem;
    --space-mega: 12rem;
    --space-ultra: 15rem;
    --margin-grid: 2.5rem;
    --margin-grid-minus: -2.5rem;

    @media @max-tablet-hoch {
        --space--xx-large: 5rem;
        --space-mega: 6rem;
        --space-ultra: 7.5rem;
    }


    /* ANIMATIONS
    _____________________________________________________________*/

    --anim-duration: .2s;
}














