.cp-audio-player {
    .page-wrapper {
        border: 1px solid var(--color-bg-box-grey-8);
        padding: var(--space-x-large);
        margin-bottom: var(--space-x-large);
    }

    .audio-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media @min-tablet-hoch {
            flex-wrap: nowrap;
        }

        .icon {
            i {
                font-size: 4.5rem;
            }
        }

        .player-wrapper {
            margin-left: 0;
            max-width: 508px;

            @media @min-tablet-hoch {
                margin-left: var(--space-large);
            }

            .el_title {
                margin-bottom: var(--space-medium);
            }

            .player {
                display: flex;
                i {
                    cursor: pointer;
                    text-decoration: none;
                    background-color: var(--color-brand);
                    color: var(--white) !important;
                    padding: var(--space-small);
                    margin-right: 5px;
                    &:before {
                        color: var(--white) !important;
                        font-size: 3rem;
                    }
                }

                .info {
                    background-color: var(--color-brand);
                    color: var(--white);
                    padding-left: var(--space-small);
                    padding-right: var(--space-small);
                    font-family: var(--font-family-medium);
                    display: flex;
                    align-items: center;

                    .title {
                        margin-right: 10px;
                        padding-right: 10px;
                        border-right: 2px solid var(--white);
                        font-weight: bold;
                        line-height: 1;
                    }

                    .duration {
                        font-weight: bold;
                        line-height: 1;
                    }
                }
            }
        }
    }
}