.el-text-block {
    .page-wrapper {
        max-width: @content-max-width;
        margin-bottom: var(--space-mega);
    }

    .inner {
        p + iframe, p + .TextformatterVideoEmbed {
            margin-top: var(--space-large);
        }

        font-size: 1.8rem;

        a {
            text-decoration: underline;
            color: var(--black);
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            line-height: var(--line-height-big);
            margin-left: var(--space-medium);

            li {
                margin: 0;
                position: relative;
                padding-left: var(--space-medium);

                &:before {
                    content: "•";
                    position: absolute;
                    left: 0;
                }
            }
        }

        .TextformatterVideoEmbed {
            position: relative;
            overflow: hidden;
            width: 100%;
            padding-top: 56.25%;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}