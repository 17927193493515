.el-contact-form{
    .page-wrapper {
        max-width: @content-max-width;
        margin-bottom: var(--space-mega);
    }

    form {
        .Inputfields {
            width: 100%;
            margin-top: var(--space-small);
            display: flex;
            flex-direction: column;

            @media @min-mobile-large {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            .Inputfield {
                width: 100% !important;

                @media @min-mobile-large {
                    width: ~"calc(50% - 20px)" !important;
                }

                &.InputfieldText,
                &.InputfieldEmail,
                &.InputfieldTextarea {


                }

                &.InputfieldPage,
                &.InputfieldTextarea {
                    @media @min-mobile-large {
                        width: 100%  !important;
                    }
                }

                &.InputfieldPage {
                    .InputfieldContent {
                        .InputfieldSelect {
                            width: 100%;

                            @media @min-mobile-large {
                                width: ~"calc(50% - 20px)";
                            }
                        }

                        .InputfieldRadios,
                        .InputfieldCheckboxes {
                            ul {
                                display: flex;
                                flex-direction: column;

                                @media @min-mobile-large {
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                }

                                li {
                                    width: 100%;

                                    @media @min-mobile-large {
                                        width: 33.33%;
                                    }

                                    @media @min-tablet-hoch {
                                        width: 20%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}