* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #e2e2e2; 
}
 
::-webkit-scrollbar-thumb {
  background: #cccccc; 
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-brand);
}

body {
  margin: 0px;
  padding: 0px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 0;
  }
}

figure {
  margin: 0;
  padding: 0;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

