.cp-doctor-teaser-gutter {
    .page-wrapper {
        max-width: 985px;
        margin-bottom: 140px;

        .inner {
            .items {
                display: flex;
                flex-wrap: wrap;
                row-gap: 80px;
                justify-content: space-between;

                @media @min-tablet-hoch {
                    column-gap: 17.5px;
                }

                .el-teaser-box-doc {
                    max-width: 100%;
                    width: 100%;

                    @media @min-tablet-hoch {
                        max-width: ~"calc(50% - 17.5px)";
                    }
                }

            }
        }
    }
}