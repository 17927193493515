.cp-side-content {
    > .page-wrapper {
        max-width: 985px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        //column-gap: var(--space-x-large);

        > .sidebar {
            display: none;
            max-width: 170px;
            width: 100%;

            @media screen and (min-width: 965px) {
                display: block;
            }

            ul {
                li {
                    margin-bottom: var(--space-small);
                    a {
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        font-size: 1.6rem;
                        font-family: var(--font-family-base);
                        &.active {
                            font-weight: bold;
                            font-family: var(--font-family-medium);
                            &:after {
                                content: @icon-up;
                                rotate: 90deg;
                                font-size: 1rem;
                                font-family: @icomoon-font-family;
                                font-weight: bold;
                                margin-left: var(--space-medium);
                            }
                        }
                    }

                    &:hover {
                        a {
                            font-family: var(--font-family-medium);
                            &:after {
                                content: @icon-up;
                                rotate: 90deg;
                                font-size: 1rem;
                                font-family: @icomoon-font-family;
                                margin-left: var(--space-medium);
                            }
                        }
                    }
                }
            }
        }

        > .content {
            max-width: 730px;
            //min-width: 567px;
            width: 100%;

            .cp-three-column-image-txt {
                h1 {
                    font-size: 4rem;
                    margin-bottom: var(--space-x-small);
                }
            }

            .cp-accordion {
                .page-wrapper {
                    margin-bottom: var(--space-mega);
                }
            }

            img {
                width: 100%;
            }

            @media @min-tablet-hoch {
                img {
                    width: fit-content;
                }
            }
        }
    }
}