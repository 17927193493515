.el-teaser-box-doc {
    .inner {
        position: relative;

        figure {
            margin-bottom: 30px;
        }

        .content {
            .link-text {
                color: var(--color-link);
                font-family: var(--font-family-medium);
            }
        }
    }

    &.one-link {
        .inner {
            .content {
                p.link-text {
                    position: relative;
                    text-decoration: none;
                    color: var(--color-brand);
                }

                p.link-text::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    background-color: var(--color-brand);
                    transition: width .6s ease-in-out;
                }
            }

            &:hover {
                p.link-text::before {
                    left: auto;
                    right: 0;
                    width: 0;
                    background-color: var(--color-brand);
                }
            }
        }
    }

    &.two-col {
        .inner {
            display: flex;
            flex-direction: column;

            @media @min-mobile-large {
                flex-direction: row;
                justify-content: space-between;
            }

            figure {
                width: 100%;
                margin-bottom: 30px;

                @media @min-mobile-large {
                    width: ~"calc(50% - 15px)";
                    margin-bottom: 0;
                }
            }

            .content {
                width: 100%;
                display: flex;
                flex-direction: column;

                @media @min-mobile-large  {
                    width: ~"calc(50% - 15px)";
                }

                a.button {
                    align-self: flex-end;
                    position: relative;
                    right: inherit;
                    bottom:  inherit;

                    @media @min-mobile-large  {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                }
            }

            a.link-box {
                display: none;
            }
        }
    }

    &.link-button {
        .inner {
            .content {
                > .link-text {
                    display: none;
                }

                .link-bar {
                    position: relative;
                    height: 60px;

                    a.link-text {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translate(0, -50%);
                        text-decoration: none;
                        color: var(--color-brand);

                        &:hover::before {
                            left: auto;
                            right: 0;
                            width: 0;
                            background-color: var(--color-brand);
                        }
                    }

                    a.link-text::before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 2px;
                        background-color: var(--color-brand);
                        transition: width .6s ease-in-out;
                    }

                    a.button {
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                }
            }
        }

        a.link-box {
            display: none;
        }
    }
}