.el-slider-item {
    &.event {
        background-color: var(--color-bg-box-brand-30);
    }

    &.news {
        background-color: var(--color-bg-box-grey-15);
    }

    .inner {
        position: relative;
        padding: 30px 30px 45px;

        header {
            .surtitle {
                color: var(--color-link);
                font-family: var(--font-family-medium);
                font-weight: var(--font-weight-bold);
            }

            h3 {
                font-size: var(--font-size-big);
                font-family: var(--font-family-medium);
            }
        }

        figure {
            margin-bottom: 30px;
        }

        .date {
            color: var(--color-link);
            font-family: var(--font-family-medium);
            font-weight: var(--font-weight-bold);
            margin: 0;
        }

        .date-info {
            font-weight: var(--font-weight-light);
        }

        .content {
            font-weight: var(--font-weight-light);
        }

        .link-text {
            position: relative;
            text-decoration: none;
            color: var(--color-link);
            font-weight: var(--font-weight-bold);
            font-family: var(--font-family-medium);
        }

        .link-text::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 1px;
            background-color: var(--color-link);
            transition: width .6s ease-in-out;
        }

    }
    &:hover {
        .link-text {
            color: var(--color-link);
        }

        .link-text::before {
            left: auto;
            right: 0;
            width: 0;
            background-color: var(--color-link);
        }
    }
}