.cp-hero-image {
    > .page-wrapper {
        max-width: 1440px;
        margin: 0 auto;
        margin-bottom: 208px;

        @media @min-tablet-hoch {
            margin-bottom: var(--space-ultra);
        }

        > .inner {
            position: relative;

            .header-overlay {
                background-color: var(--color-brand-light);
                display: block;
                max-width: 100%;
                position: absolute;
                bottom: -173px;
                left: var(--space-medium);
                right: var(--space-medium);
                color: var(--white);
                padding: 40px 50px 40px 50px;
                opacity: 0.85;
                font-size: 28px;
                line-height: 36px;
                margin: 0 auto;
                z-index: 10;

                @media @min-mobile-medium {
                    font-size: 32px;
                    line-height: 45px;
                }

                @media @min-tablet-hoch {
                    max-width: 737px;
                    bottom: -78px;
                    left: 0px;
                    right: 0px;
                    font-size: 43px;
                    line-height: 55px;
                }
            }

            .overlay-bg {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0,0,0,0.2);
            }

            .image-container {
                line-height: 0;

                img {
                    width: 100%;
                }
            }
        }
    }
}

section {
    &.hero-image {
        > .page-wrapper {
            max-width: 1440px;
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}