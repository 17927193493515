@icomoon-font-family: "icomoon";
@icomoon-font-path: "../vendor/icomoon/fonts";

@icon-mail: "\e905";
@icon-facebook: "\e900";
@icon-instagram: "\e901";
@icon-linkedin: "\e902";
@icon-youtube: "\e903";
@icon-favicon: "\e904";
@icon-menu: "\e908";
@icon-close: "\e909";
@icon-breadcrumb: "\e90a";
@icon-down: "\e90b";
@icon-up: "\e90c";
@icon-next: "\e90e";
@icon-prev: "\e90f";
@icon-play: "\e910";
@icon-publikationen: "\e913";
@icon-search: "\e914";
@icon-phone: "\e915";
@icon-calendar: "\e916";

