.cp-header {
    width: 100%;
    background-color: var(--white);
    color: var(--black);
    position: relative;
    z-index: 10000;

    .inner {
        padding: 1.8rem 0 1.5rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .logo-bar {
            width: 65%;

            @media @min-mobile-medium {
                width: 50%;
            }

            @media @min-tablet-quer {
                width: 25%;
            }

            .logo {
                margin-top: 0.5rem;
                height: 59px;
                width: 100%;

                @media @min-mobile-medium {
                    height: 35px;
                    width: auto;
                }

                @media @min-mobile-large {
                    height: 50px;
                }

                @media @min-tablet-quer {
                    height: 75px;
                }
            }
        }

        .nav-bar {
            width: 35%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            @media @min-mobile-medium {
                width: 50%;
            }

            @media @min-tablet-quer {
                width: 75%;
            }

            .search {
                width: 100%;
                max-width: 986px;
                margin: 0 auto;
                position: relative;
                display: block;
                justify-content: flex-end;
                align-items: center;
                margin-top: 60px;
                margin-bottom: 60px;
                padding-left: var(--space-medium);
                padding-right: var(--space-medium);
                padding-bottom: var(--space-medium);
                @media @min-tablet-quer {
                    padding: 0;
                    display: flex;
                }

                .lang-switch {
                    margin-bottom: 1.8rem;
                    display: flex!important;
                    @media @min-tablet-hoch {
                        display: none!important;
                    }
                }

                .el-button-bar {
                    flex-direction: row;
                    max-width: 100%;
                    margin-bottom: var(--space-medium);

                    a.button {
                        width: 100%;
                        margin: 0 var(--space-small);
                        margin-bottom: var(--space-small);
                        margin-right: 0px;
                        margin-left: 0px;

                        &:last-child {
                            margin-bottom: 0px;
                        }
                    }

                    @media @min-mobile-medium {
                        a.button {
                            width: auto;
                            margin: 0 var(--space-small);
                            &:first-child {
                                margin-left: 0;
                            }

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }

                    @media @min-tablet-quer {
                        max-width: ~"calc(100% - 546px)";
                        margin-bottom: 0px;
                    }
                }

                .search-form-container {
                    width: 100%;
                    max-width: 546px;
                    position: relative;

                    span.icon {
                        position: absolute;
                        left: 20px;
                        color: var(--black);
                        background: var(--color-bg-box-grey-100);
                        font-size: 2.3rem;
                        font-weight: var(--font-weight-bold);
                        text-decoration: none;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    form.search-form {
                        width: 100%;
                        position: relative;
                        overflow: hidden;
                        float: right;
                        transition: width var(--anim-duration) ease-in-out;

                        input {
                            background-color: var(--color-bg-box-grey-100);
                            width: 100%;
                            height: 60px;
                            padding: 0 10px;
                            padding-left: 64px;
                            border: 0;
                            font-size: 18px;
                            font-weight: var(--font-weight-regular);
                            font-family: var(--font-family-base);
                            color: var(--black-30);

                            &:focus {
                                outline: none !important;
                                border-color: transparent;
                                box-shadow: none;
                            }
                        }
                    }
                }
            }

            .nav-container {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-self: center;
                align-items: baseline;

                @media @min-mobile-medium {
                    align-self: flex-end;
                }

                .lang-switch {
                    display: none;
                    height: fit-content;
                    margin-right: var(--space-large);

                    @media @min-tablet-hoch {
                        display: flex;
                    }

                    li {
                        border-right: 2px solid var(--color-bg-box-grey-8);
                        padding-left: var(--space-x-small);
                        padding-right: var(--space-x-small);
                        line-height: 1;
                        &.active {
                            a {
                                font-weight: bold;
                                color: var(--black);
                                font-family: var(--font-family-medium);
                            }
                        }
                        &:hover {
                            a {
                                color: var(--color-brand);
                            }
                        }
                        &:first-child {
                            padding-left: 0px;
                        }
                        &:last-child {
                            border: none;
                        }
                        a {
                            color: var(--color-bg-box-grey-8);
                            text-decoration: none;
                            font-size: 1.8rem;
                        }
                    }
                }

                .main-nav {
                    z-index: 1;
                    width: 100%;
                    left: 0px;
                    right: 0px;
                    height: 100vh;
                    position: absolute;
                    padding-top: var(--space-medium);
                    top: 101px;
                    background-color: var(--white);
                    transition: height var(--anim-duration) ease-in-out;
                    overflow: hidden;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;

                    &.open {
                        opacity: 1;
                        visibility: visible;
                    }

                    &.hidden {
                        display: none;
                    }

                    @media @min-mobile-medium {
                        top: 78px;
                    }

                    @media @min-mobile-large {
                        top: 93px;
                    }

                    @media @min-tablet-quer {
                        top: 118px;
                    }

                    /*&:not(.open) {
                        height: 0;
                    }*/

                    /*@media @min-tablet-quer {
                        position: initial;
                        display: flex !important;
                        align-self: flex-end;
                        width: 100%;
                        height: auto !important;
                    }*/

                    .level-1 {
                        list-style: none;
                        padding: var(--space-medium);
                        max-width: 986px;
                        margin: 0 auto;
                        @media @min-tablet-quer {
                            padding: 0;
                        }

                        > li {
                            position: relative;
                            display: block;
                            max-width: 100%;
                            margin-right: 0;
                            padding: var(--space-x-small) 0;

                            @media @min-tablet-hoch {
                                max-width: fit-content;
                            }

                            > a {
                                font-family: var(--font-family-alt)!important;
                                &:hover {
                                    opacity: 0.5;
                                }
                            }

                            a {
                                font-family: var(--font-family-base);
                                font-weight: var(--font-weight-bold);
                                color: var(--black);
                                font-size: 22px;
                                text-decoration: none;
                            }

                            .level-2 {
                                position: relative;
                                padding-top: 15px;
                                width: 100%;
                                display: none;
                                padding-bottom: var(--space-medium);

                                @media @min-tablet-hoch {
                                    width: 250px;
                                }

                                li {
                                    display: flex;
                                    flex-wrap: wrap;
                                    flex-direction: row-reverse;
                                    justify-content: flex-end;
                                    padding: var(--space-x-small) 0;
                                    border-top: 1px solid var(--color-bg-box-grey-15);
                                    padding: var(--space-x-small) var(--space-medium);
                                    padding-left: 0px;

                                    a {
                                        font-size: 18px;
                                    }

                                    &:hover,  &.open {
                                        > a {
                                            color: var(--color-brand);
                                            font-family: var(--font-family-medium);
                                        }
                                        &:before {
                                            color: var(--color-brand);
                                        }
                                    }

                                    &:last-child {
                                        border-bottom: 1px solid var(--color-bg-box-grey-15);
                                    }

                                    .level-3 {
                                        display: none;
                                        width: 100%;
                                        position: relative;
                                        margin-left: 20px;
                                        margin-bottom: var(--space-medium);

                                        li {
                                            border-top: none;
                                            &:last-child {
                                                border-bottom: none;
                                            }
                                            &.top {
                                                a {
                                                    //font-weight: bold;
                                                    color: var(--color-brand);
                                                    font-family: var(--font-family-book);
                                                    &:hover {
                                                        font-family: var(--font-family-medium);
                                                    }
                                                }
                                                &:first-child {
                                                    border-top: none;
                                                    padding-top: var(--space-x-small);
                                                    margin-top: 10px;
                                                }
                                                border-top: 1px solid var(--color-bg-box-grey-15);
                                                padding-top: 10px;
                                                margin-top: 10px;
                                            }
                                        }

                                        @media @min-tablet-hoch {
                                            position: absolute;
                                            width: ~"calc(100% * 2)";
                                            left: 250px;
                                            top: 15px;
                                            columns: 2;
                                            -webkit-columns: 2;
                                            -moz-columns: 2;

                                            li {
                                                border-bottom: 1px solid var(--color-bg-box-grey-15);
                                                &.top {
                                                    &:first-child {
                                                        border-top: 1px solid var(--color-bg-box-grey-15);
                                                        padding-top: var(--space-x-small);
                                                        margin-top: initial;
                                                    }
                                                    padding-top: var(--space-x-small);
                                                    margin-top: initial;
                                                }
                                                &:last-child {
                                                    border-bottom: 1px solid var(--color-bg-box-grey-15);
                                                }
                                            }
                                        }
                                    }

                                    &.has-children {
                                        &:before {
                                            content: @icon-breadcrumb;

                                            font-family: @icomoon-font-family;
                                            font-size: 1rem;
                                            float: right;
                                            padding-left: 10px;
                                            cursor: pointer;
                                        }

                                        &.open {
                                            &:before {
                                                content: @icon-breadcrumb;
                                            }
                                            .level-3 {
                                                display: block;
                                            }
                                        }
                                    }

                                }
                            }

                            &.has-children {
                                &:before {
                                    content: @icon-down;
                                    font-family: @icomoon-font-family;
                                    float: right;
                                    padding-left: 15px;
                                    font-size: 1rem;
                                    cursor: pointer;
                                }

                                &.open {
                                    &:before {
                                        content: @icon-up;
                                    }
                                    .level-2 {
                                        display: block;
                                    }
                                }
                            }

                        }
                    }
                }

                .mob-nav {
                    display: flex;
                    margin-bottom: 0px;

                    @media @min-mobile-medium {
                        margin-bottom: 15px;
                    }

                    a {
                        display: flex;
                        align-self: center;
                        //width: 35px;;
                        font-weight: var(--font-weight-bold);
                        color: var(--black);
                        margin-left: 25px;
                        text-decoration: none;


                        &:before {
                            font-size: 2.5rem;
                            @media @min-mobile-medium {
                                font-size: 2rem;
                            }
                        }

                    }

                    @media @min-tablet-hoch {
                        font-size: 28px;
                    }

                   /* @media @min-tablet-quer {
                        display: none;
                    }*/

                    .icon-1, .icon-2, .icon-3 {
                        position: absolute;
                        left: 25%;
                        top: 50%;
                        width: 35px;
                        height: 1px;
                        background-color: black;
                        transition: all 400ms cubic-bezier(.84,.06,.52,1.8);
                    }

                    .icon-1 {
                        transform: translateY(-10px);
                        animation-delay: 100ms;
                    }

                    .icon-3 {
                        transform: translateY(10px);
                        animation-delay: 250ms;
                    }

                    .hamburger-icon {
                        position: relative;
                        width: 37.93px;
                        height: 20px;
                    }

                    .icon-1.a {
                        transform: rotate(40deg);
                    }
                    .icon-3.b {
                        transform: rotate(-40deg);
                    }
                    .icon-2.c {
                        opacity: 0;
                    }

                    .clear {
                        clear: both;
                    }

                    @keyframes slideIn {
                        0% {
                            width: 0%;
                            opacity: 0;
                        }

                        100% {
                            width: 50%;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
