.cp-page-section {
    .page-wrapper {
        max-width: 1010px;
    }

    .el_title {
        margin-bottom: var(--space--xx-large);
        max-width: 850px;
    }

    .cp-graphic-teasers {
        margin-bottom: var(--space--xx-large);

        &:last-child {
            margin-bottom: 0px;
        }
    }

    &.small {
        .page-wrapper {
            max-width: 800px;
        }
    }
}
